<template>
  <div>
    <error-404-content />
  </div>
</template>

<script>
import Error404Content from 'components/errors/Error404Content.vue';

export default {
  name: 'Error404',
  components: {
    Error404Content,
  },
  serverPrefetch() {
    this.$store.commit('SET_APP_HTTPSTATUS', 404);
    return Promise.resolve();
  },
};
</script>
