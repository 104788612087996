import { render, staticRenderFns } from "./Error404Content.vue?vue&type=template&id=f40f8c12&scoped=true"
import script from "./Error404Content.vue?vue&type=script&lang=js"
export * from "./Error404Content.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Error404Content.vue?vue&type=style&index=0&id=f40f8c12&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("scss/components/search/HeaderSearch.scss?vue&type=style&index=1&id=f40f8c12&prod&lang=scss&scoped=true&external")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f40f8c12",
  "380e7a40"
  
)

export default component.exports