import { render, staticRenderFns } from "./Error410Content.vue?vue&type=template&id=c8c71bfe&scoped=true"
import script from "./Error410Content.vue?vue&type=script&lang=js"
export * from "./Error410Content.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Error410Content.vue?vue&type=style&index=0&id=c8c71bfe&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("scss/components/search/HeaderSearch.scss?vue&type=style&index=1&id=c8c71bfe&prod&lang=scss&scoped=true&external")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c8c71bfe",
  "98959836"
  
)

export default component.exports