<template>
  <div class="container">
    <section>
      <p>
        Sie werden in Kürze weitergeleitet...
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Error301Content',
};
</script>
