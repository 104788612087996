<template>
  <div class="error-404 no-footer-margin">
    <div class="bg">
      <hero-image
        :img-src="backgroundImage"
        :extra-class="'hero-image'"
      />
    </div>
    <div class="content">
      <h1
        v-if="!errorTextObject.header"
        class="mb-4"
      >
        {{ $t('errorPages:error404.headline') }}
      </h1>
      <h1
        v-else
        class="mb-4"
      >
        {{ errorTextObject.header }}
      </h1>

      <p class="h2">
        {{ $t('errorPages:error404.firstParagraph') }}
      </p>
      <p class="h2">
        {{ $t('errorPages:error404.secondParagraph') }}
      </p>
      <div class="flex-button-container">
        <router-link-simple
          :to="{ name: 'Search'}"
          class="btn btn-primary btn-center"
        >
          {{ $t('errorPages:cta.search') }}
        </router-link-simple>
        <router-link-simple
          :to="{ name: 'Home'}"
          class="btn btn-primary btn-center"
        >
          {{ $t('errorPages:cta.home') }}
        </router-link-simple>
      </div>

      <p class="small">
        <strong>{{ $t('errorPages:errormessageTitle') }}</strong><br>
        {{ $t('errorPages:error404.errormessageInformation') }} {{ $route.path }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RouterLinkSimple from 'components/RouterLinkSimple.vue';
import HeroImage from 'components/HeroImage.vue';
import configHeroImages from 'config/app/heroImages';

export default {
  name: 'Error404Content',
  components: {
    RouterLinkSimple,
    HeroImage,
  },
  i18nOptions: { namespaces: 'errorPages' },
  computed: {
    ...mapGetters([
      'getErrorTextObject',
    ]),
    errorTextObject() {
      return this.getErrorTextObject || '';
    },
    backgroundImage() {
      return configHeroImages.heroImages.error404;
    },
  },
  serverPrefetch() {
    this.$store.commit('SET_APP_HTTPSTATUS', 404);
    return Promise.resolve();
  },
};
</script>

<style lang="scss" scoped>
.error-404 {
  margin-bottom: -3em;
  color: #FFF;
}
.btn { // all buttons should have the same minimal-width but should expand if text is bigger
  min-width: 10em;
}
.error-404.no-footer-margin {
  .content{
    bottom: 25%;
    @media #{$media_sm_max}{
      padding: 25% 0;
      background: none;
      margin: 0 $margin_content;
    }
    word-break: break-word;
  }
}
.flex-button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $margin_content;
  margin-bottom: $margin_content;
}
</style>
<style lang="scss" src="scss/components/search/HeaderSearch.scss" scoped></style>
