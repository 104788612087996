<template>
  <div>
    <error-410-content />
  </div>
</template>

<script>
import Error410Content from 'components/errors/Error410Content.vue';

export default {
  name: 'Error410',
  components: {
    Error410Content,
  },
  serverPrefetch() {
    this.$store.commit('SET_APP_HTTPSTATUS', 410);
    return Promise.resolve();
  },
};
</script>
