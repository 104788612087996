<template>
  <picture class="d-flex hero-picture">
    <source
      v-if="!errorRecreated"
      :srcset="imgObj.srcsetWebp"
      type="image/webp"
    >
    <source
      v-if="!errorRecreated"
      :srcset="imgObj.srcset"
      type="image/jpeg"
    >
    <img
      ref="imgMain"
      :src="imgObj.preview"
      :srcset="imgObj.srcset"
      :sizes="imgObj.sizes"
      :title="title"
      :alt="alt"
      class="img-full hero-picture-img"
      :class="{
        [extraClass]: extraClass !== '',
      }"
      @error="imgLoadErr"
    >
  </picture>
</template>

<script>

export default {
  name: 'HeroImage',
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: 'Location Image',
    },
    title: {
      type: String,
      default: '',
    },
    imgType: {
      type: String,
      default: 'default',
    },
    extraClass: {
      type: String,
      default: '',
    },
    defaultDimension: {
      type: String,
      default: '425x320',
    },
  },
  data() {
    return {
      errorRecreated: false, // prevent endless loop, only try re-creating img src once!
      srcsetConfig: {
        default: this.defaultDimension,
        sizes: {
          default: '(min-width: 1400px) 1600px, (min-width: 1000px) 1200px,(min-width: 768px) 800px,(min-width: 600px) 600px, (max-width: 400px) 400px, 100vw',
        },
        srcset: {
          /** 1600 1200 800 600 400 */
          default: [
            {
              dimension: '1600',
              width: '1600',
            },
            {
              dimension: '1200',
              width: '1200',
            },
            {
              dimension: '800',
              width: '800',
            },
            {
              dimension: '600',
              width: '600',
            },
            {
              dimension: '400',
              width: '400',
            },
          ],
        },
      },
    };
  },
  computed: {
    imgObj() {
      const sizes = this.srcsetConfig.sizes[this.imgType];
      const width = this.defaultWidth;
      const srcString = this.imgSrc;
      if (srcString) {
        const strings = [];
        // build srcset and sizes string for <img> attributes, replace /width/ with config width
        Object.values(this.srcsetConfig.srcset[this.imgType]).forEach((srcsetData) => {
          strings.push(`${srcString.replace('width', srcsetData.width)} ${srcsetData.width}w`);
        });
        const stringsWebp = [];
        Object.values(this.srcsetConfig.srcset[this.imgType]).forEach((srcsetData) => {
          stringsWebp.push(`${srcString.replace('width', srcsetData.width).replace('.jpg', '.webp')} ${srcsetData.width}w`);
        });

        // background images
        return {
          sizes,
          srcset: strings.join(','),
          srcsetWebp: stringsWebp.join(','),
          src: srcString.replace('width', width),
          srcWebp: srcString.replace('width', width).replace('.jpg', '.webp'),
        };
      }
      return {};
    },
    defaultWidth() {
      return this.defaultDimension.split('x')[0];
    },
  },
  methods: {
    imgLoadErr(event) {
      // fallback: change img src from webp to jpg
      // only try once to prevent endless loop!
      if (!this.errorRecreated) {
        this.errorRecreated = true;
        if (event.target?.currentSrc) {
          // replace webp with jpg
          const imgIsWebpMatch = event.target?.currentSrc.match(/\.webp$/i);
          if (imgIsWebpMatch && imgIsWebpMatch[0]) {
            const failedSrc = event.target.currentSrc;
            if (this.$refs.imgMain) {
              this.$refs.imgMain.srcset = event.target.currentSrc.replace(/\.webp$/i, '.jpg');
            }

            const imgErr = new Error(`could not load WEBP hero image ${failedSrc} on ${window.location.href}`);
            if (process.env.NODE_ENV !== 'production') {
              // eslint-disable-next-line no-console
              console.error(imgErr);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lazy {
  will-change: transform; // gives some performance
}

.blur {
  filter: blur(2px);
}

.hero-picture {
  width: 100%;
  height: 50vh;
  min-height: 100%;
  .hero-picture-img {
    object-fit: cover;
  }
}
</style>
