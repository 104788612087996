<template>
  <div>
    <error-301-content />
  </div>
</template>

<script>
import Error301Content from 'components/errors/Error301Content.vue';

export default {
  name: 'Error301',
  components: {
    Error301Content,
  },
};
</script>
